module.exports['abs'] = require('./abs')
module.exports['acos'] = require('./acos')
module.exports['acosh'] = require('./acosh')
module.exports['asin'] = require('./asin')
module.exports['asinh'] = require('./asinh')
module.exports['atan'] = require('./atan')
module.exports['atan2'] = require('./atan2')
module.exports['atanh'] = require('./atanh')
module.exports['base_convert'] = require('./base_convert')
module.exports['bindec'] = require('./bindec')
module.exports['ceil'] = require('./ceil')
module.exports['cos'] = require('./cos')
module.exports['cosh'] = require('./cosh')
module.exports['decbin'] = require('./decbin')
module.exports['dechex'] = require('./dechex')
module.exports['decoct'] = require('./decoct')
module.exports['deg2rad'] = require('./deg2rad')
module.exports['exp'] = require('./exp')
module.exports['expm1'] = require('./expm1')
module.exports['floor'] = require('./floor')
module.exports['fmod'] = require('./fmod')
module.exports['getrandmax'] = require('./getrandmax')
module.exports['hexdec'] = require('./hexdec')
module.exports['hypot'] = require('./hypot')
module.exports['is_finite'] = require('./is_finite')
module.exports['is_infinite'] = require('./is_infinite')
module.exports['is_nan'] = require('./is_nan')
module.exports['lcg_value'] = require('./lcg_value')
module.exports['log'] = require('./log')
module.exports['log10'] = require('./log10')
module.exports['log1p'] = require('./log1p')
module.exports['max'] = require('./max')
module.exports['min'] = require('./min')
module.exports['mt_getrandmax'] = require('./mt_getrandmax')
module.exports['mt_rand'] = require('./mt_rand')
module.exports['octdec'] = require('./octdec')
module.exports['pi'] = require('./pi')
module.exports['pow'] = require('./pow')
module.exports['rad2deg'] = require('./rad2deg')
module.exports['rand'] = require('./rand')
module.exports['round'] = require('./round')
module.exports['sin'] = require('./sin')
module.exports['sinh'] = require('./sinh')
module.exports['sqrt'] = require('./sqrt')
module.exports['tan'] = require('./tan')
module.exports['tanh'] = require('./tanh')
