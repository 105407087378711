module.exports['boolval'] = require('./boolval')
module.exports['doubleval'] = require('./doubleval')
module.exports['empty'] = require('./empty')
module.exports['floatval'] = require('./floatval')
module.exports['gettype'] = require('./gettype')
module.exports['intval'] = require('./intval')
module.exports['is_array'] = require('./is_array')
module.exports['is_binary'] = require('./is_binary')
module.exports['is_bool'] = require('./is_bool')
module.exports['is_buffer'] = require('./is_buffer')
module.exports['is_callable'] = require('./is_callable')
module.exports['is_double'] = require('./is_double')
module.exports['is_float'] = require('./is_float')
module.exports['is_int'] = require('./is_int')
module.exports['is_integer'] = require('./is_integer')
module.exports['is_long'] = require('./is_long')
module.exports['is_null'] = require('./is_null')
module.exports['is_numeric'] = require('./is_numeric')
module.exports['is_object'] = require('./is_object')
module.exports['is_real'] = require('./is_real')
module.exports['is_scalar'] = require('./is_scalar')
module.exports['is_string'] = require('./is_string')
module.exports['is_unicode'] = require('./is_unicode')
module.exports['isset'] = require('./isset')
module.exports['print_r'] = require('./print_r')
module.exports['serialize'] = require('./serialize')
module.exports['strval'] = require('./strval')
module.exports['unserialize'] = require('./unserialize')
module.exports['var_dump'] = require('./var_dump')
module.exports['var_export'] = require('./var_export')
