module.exports['ctype_alnum'] = require('./ctype_alnum')
module.exports['ctype_alpha'] = require('./ctype_alpha')
module.exports['ctype_cntrl'] = require('./ctype_cntrl')
module.exports['ctype_digit'] = require('./ctype_digit')
module.exports['ctype_graph'] = require('./ctype_graph')
module.exports['ctype_lower'] = require('./ctype_lower')
module.exports['ctype_print'] = require('./ctype_print')
module.exports['ctype_punct'] = require('./ctype_punct')
module.exports['ctype_space'] = require('./ctype_space')
module.exports['ctype_upper'] = require('./ctype_upper')
module.exports['ctype_xdigit'] = require('./ctype_xdigit')
