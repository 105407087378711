module.exports['array'] = require('./array')
module.exports['bc'] = require('./bc')
module.exports['ctype'] = require('./ctype')
module.exports['datetime'] = require('./datetime')
module.exports['exec'] = require('./exec')
module.exports['filesystem'] = require('./filesystem')
module.exports['funchand'] = require('./funchand')
module.exports['i18n'] = require('./i18n')
module.exports['info'] = require('./info')
module.exports['json'] = require('./json')
module.exports['math'] = require('./math')
module.exports['misc'] = require('./misc')
module.exports['net-gopher'] = require('./net-gopher')
module.exports['network'] = require('./network')
module.exports['pcre'] = require('./pcre')
module.exports['strings'] = require('./strings')
module.exports['url'] = require('./url')
module.exports['var'] = require('./var')
module.exports['xdiff'] = require('./xdiff')
module.exports['xml'] = require('./xml')
