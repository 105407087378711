module.exports['addcslashes'] = require('./addcslashes')
module.exports['addslashes'] = require('./addslashes')
module.exports['bin2hex'] = require('./bin2hex')
module.exports['chop'] = require('./chop')
module.exports['chr'] = require('./chr')
module.exports['chunk_split'] = require('./chunk_split')
module.exports['convert_cyr_string'] = require('./convert_cyr_string')
module.exports['convert_uuencode'] = require('./convert_uuencode')
module.exports['count_chars'] = require('./count_chars')
module.exports['crc32'] = require('./crc32')
module.exports['echo'] = require('./echo')
module.exports['explode'] = require('./explode')
module.exports['get_html_translation_table'] = require('./get_html_translation_table')
module.exports['hex2bin'] = require('./hex2bin')
module.exports['html_entity_decode'] = require('./html_entity_decode')
module.exports['htmlentities'] = require('./htmlentities')
module.exports['htmlspecialchars'] = require('./htmlspecialchars')
module.exports['htmlspecialchars_decode'] = require('./htmlspecialchars_decode')
module.exports['implode'] = require('./implode')
module.exports['join'] = require('./join')
module.exports['lcfirst'] = require('./lcfirst')
module.exports['levenshtein'] = require('./levenshtein')
module.exports['localeconv'] = require('./localeconv')
module.exports['ltrim'] = require('./ltrim')
module.exports['md5'] = require('./md5')
module.exports['md5_file'] = require('./md5_file')
module.exports['metaphone'] = require('./metaphone')
module.exports['money_format'] = require('./money_format')
module.exports['nl2br'] = require('./nl2br')
module.exports['nl_langinfo'] = require('./nl_langinfo')
module.exports['number_format'] = require('./number_format')
module.exports['ord'] = require('./ord')
module.exports['parse_str'] = require('./parse_str')
module.exports['printf'] = require('./printf')
module.exports['quoted_printable_decode'] = require('./quoted_printable_decode')
module.exports['quoted_printable_encode'] = require('./quoted_printable_encode')
module.exports['quotemeta'] = require('./quotemeta')
module.exports['rtrim'] = require('./rtrim')
module.exports['setlocale'] = require('./setlocale')
module.exports['sha1'] = require('./sha1')
module.exports['sha1_file'] = require('./sha1_file')
module.exports['similar_text'] = require('./similar_text')
module.exports['soundex'] = require('./soundex')
module.exports['split'] = require('./split')
module.exports['sprintf'] = require('./sprintf')
module.exports['sscanf'] = require('./sscanf')
module.exports['str_getcsv'] = require('./str_getcsv')
module.exports['str_ireplace'] = require('./str_ireplace')
module.exports['str_pad'] = require('./str_pad')
module.exports['str_repeat'] = require('./str_repeat')
module.exports['str_replace'] = require('./str_replace')
module.exports['str_rot13'] = require('./str_rot13')
module.exports['str_shuffle'] = require('./str_shuffle')
module.exports['str_split'] = require('./str_split')
module.exports['str_word_count'] = require('./str_word_count')
module.exports['strcasecmp'] = require('./strcasecmp')
module.exports['strchr'] = require('./strchr')
module.exports['strcmp'] = require('./strcmp')
module.exports['strcoll'] = require('./strcoll')
module.exports['strcspn'] = require('./strcspn')
module.exports['strip_tags'] = require('./strip_tags')
module.exports['stripos'] = require('./stripos')
module.exports['stripslashes'] = require('./stripslashes')
module.exports['stristr'] = require('./stristr')
module.exports['strlen'] = require('./strlen')
module.exports['strnatcasecmp'] = require('./strnatcasecmp')
module.exports['strnatcmp'] = require('./strnatcmp')
module.exports['strncasecmp'] = require('./strncasecmp')
module.exports['strncmp'] = require('./strncmp')
module.exports['strpbrk'] = require('./strpbrk')
module.exports['strpos'] = require('./strpos')
module.exports['strrchr'] = require('./strrchr')
module.exports['strrev'] = require('./strrev')
module.exports['strripos'] = require('./strripos')
module.exports['strrpos'] = require('./strrpos')
module.exports['strspn'] = require('./strspn')
module.exports['strstr'] = require('./strstr')
module.exports['strtok'] = require('./strtok')
module.exports['strtolower'] = require('./strtolower')
module.exports['strtoupper'] = require('./strtoupper')
module.exports['strtr'] = require('./strtr')
module.exports['substr'] = require('./substr')
module.exports['substr_compare'] = require('./substr_compare')
module.exports['substr_count'] = require('./substr_count')
module.exports['substr_replace'] = require('./substr_replace')
module.exports['trim'] = require('./trim')
module.exports['ucfirst'] = require('./ucfirst')
module.exports['ucwords'] = require('./ucwords')
module.exports['vprintf'] = require('./vprintf')
module.exports['vsprintf'] = require('./vsprintf')
module.exports['wordwrap'] = require('./wordwrap')
